import React, { lazy, Suspense } from "react";
import { WEB_COMPONENT_TYPES } from "Src/constants";
import { TWebComponentAttributes, TWebComponentType } from "../types";
import { FallbackContainer } from "./Fallback/FallbackContainer";

const ComponentNotFound = () => <div>Component Not Found</div>;
const Loading = () => <div></div>;

type Props = {
  attributes: TWebComponentAttributes;
};

const RgBar = lazy(async () => await import("./RgBar"));
const GameplayRgBar = lazy(async () => await import("./GameplayRgBar"));
const SwedishLoginLimits = lazy(
  async () => await import("./SwedishLoginLimits")
);
const SeonSession = lazy(async () => await import("./SeonSession"));
const BeforeLogoutModal = lazy(async () => await import("./BeforeLogout"));
const IntercomChat = lazy(async () => await import("./IntercomChat"));
const LiveChat = lazy(async () => await import("./LiveChat"));
const MandatoryMessages = lazy(async () => await import("./MandatoryMessages"));
const SessionConfiguration = lazy(
  async () => await import("./SessionConfiguration")
);
const GameInfoBar = lazy(
  async () => await import("./SessionConfiguration/GameInfoBar")
);
const Sessions = lazy(async () => await import("./Sessions"));
const CookiesNotice = lazy(async () => await import("./CookiesNotice"));
const CookiesSettings = lazy(async () => await import("./CookiesSettings"));
const AccountVerification = lazy(
  async () => await import("./AccountVerification")
);
const SOWi = lazy(async () => await import("./SOWi"));
const Kambi = lazy(async () => await import("./Kambi"));
const LegalDocuments = lazy(async () => await import("./LegalDocuments"));
const LegalDocumentsStatic = lazy(
  async () => await import("./LegalDocumentsStatic")
);

const MonthlySummaries = lazy(async () => await import("./Summaries"));

const WEB_COMPONENT_MAP: Record<
  TWebComponentType,
  React.LazyExoticComponent<
    ((props: any) => JSX.Element | null) | React.NamedExoticComponent<object>
  >
> = {
  [WEB_COMPONENT_TYPES.RG_BAR]: RgBar,
  [WEB_COMPONENT_TYPES.GAMEPLAY_RG_BAR]: GameplayRgBar,
  [WEB_COMPONENT_TYPES.SWEDISH_LOGIN_LIMITS]: SwedishLoginLimits,
  [WEB_COMPONENT_TYPES.SEON_SESSION]: SeonSession,
  [WEB_COMPONENT_TYPES.INTERCOM_CHAT]: IntercomChat,
  [WEB_COMPONENT_TYPES.LIVE_CHAT]: LiveChat,
  [WEB_COMPONENT_TYPES.MANDATORY_MESSAGES]: MandatoryMessages,
  [WEB_COMPONENT_TYPES.SESSION_CONFIGURATION]: SessionConfiguration,
  [WEB_COMPONENT_TYPES.GAME_INFO_BAR]: GameInfoBar,
  [WEB_COMPONENT_TYPES.BEFORE_LOGOUT_MODAL]: BeforeLogoutModal,
  [WEB_COMPONENT_TYPES.SESSIONS]: Sessions,
  [WEB_COMPONENT_TYPES.COOKIES_NOTICE]: CookiesNotice,
  [WEB_COMPONENT_TYPES.COOKIES_SETTINGS]: CookiesSettings,
  [WEB_COMPONENT_TYPES.ACCOUNT_VERIFICATION]: AccountVerification,
  [WEB_COMPONENT_TYPES.KAMBI]: Kambi,
  [WEB_COMPONENT_TYPES.SOWi]: SOWi,
  [WEB_COMPONENT_TYPES.LEGAL_DOCUMENTS]: LegalDocuments,
  [WEB_COMPONENT_TYPES.LEGAL_DOCUMENTS_STATIC]: LegalDocumentsStatic,
  [WEB_COMPONENT_TYPES.MONTHLY_SUMMARIES]: MonthlySummaries,
};

const MappedWebComponent = ({ attributes }: Props) => {
  const Component = WEB_COMPONENT_MAP[
    attributes?.component
  ] as React.LazyExoticComponent<
    (props: TWebComponentAttributes) => JSX.Element
  >;

  if (!Component) {
    return <ComponentNotFound />;
  }

  return <Component {...attributes} />;
};

export const App = (attributes: TWebComponentAttributes) => {
  return (
    // @ts-ignore
    <FallbackContainer>
      {attributes ? (
        // @ts-ignore ignoring due to parcel suspense fallback/children type issues
        <Suspense fallback={<Loading />}>
          {/* @ts-ignore ignoring due to parcel suspense fallback/children type issues */}
          <MappedWebComponent attributes={attributes} />
        </Suspense>
      ) : (
        <ComponentNotFound />
      )}
    </FallbackContainer>
  );
};
