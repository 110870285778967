import { render } from "preact";
import { define } from "@casumo/fabric-components";
import { App } from "Components/App";
import { createRoot, type Root } from "react-dom/client";
import { APPLICATION_NAME } from "./constants";
import {
  createApplicationContainer,
  createStylesLink,
  createWebComponent,
  TRootNode,
} from "./packager/index";
import { TWebComponentAttributes } from "./types";
import "./index.css";

define();

const renderApplication = async (
  rootNode: TRootNode<Root>,
  node: HTMLElement,
  attributes: TWebComponentAttributes,
  onReady: () => void
) => {
  const root = createRoot(node);

  render(<App {...attributes} />, node);

  rootNode.applicationNode = root;

  if (window.requestIdleCallback) {
    window.requestIdleCallback(onReady);
  } else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(onReady, 1);
  }
};

createWebComponent<Root>(
  APPLICATION_NAME,
  (root, attributes, host, onReady) => {
    Promise.all([
      createApplicationContainer(root),
      createStylesLink(root, `${host}/index.css`),
    ]).then(([container]) => {
      renderApplication(
        root,
        container,
        attributes as TWebComponentAttributes,
        onReady
      );
    });
  },
  (node) => {
    node?.applicationNode?.unmount();
  }
);
